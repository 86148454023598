import { FC, use } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useStytchB2BClient, useStytchMember } from '@stytch/nextjs/b2b'

import { HeaderWrapper, LogoWrapper } from './style'
import { PrimaryButton } from '@components/core/buttons'
import { ThemeSize } from '@lib/theme'
import { revokeStytchSession } from '@lib/stytch/client'

const Header: FC = () => {
  const router = useRouter()
  const stytch = useStytchB2BClient()
  const { isInitialized, member } = useStytchMember()

  return (
    <HeaderWrapper>
      {isInitialized && member ? (
        <Link href="/dashboard">
          <LogoWrapper src="/images/glisseo-logo-header.svg" alt="Glisseo" />
        </Link>
      ) : (
        <Link href="/">
          <LogoWrapper src="/images/glisseo-logo-header.svg" alt="Glisseo" />
        </Link>
      )}

      {isInitialized && member ? (
        <PrimaryButton
          onClick={async () => {
            if (router.pathname.startsWith('/dashboard')) {
              await revokeStytchSession(stytch)
              router.push('/login')
            } else {
              router.push('/dasbhoard')
            }
          }}
          size={ThemeSize.Medium}
        >
          {router.pathname.startsWith('/dashboard') ? 'Log out' : 'Dashboard'}
        </PrimaryButton>
      ) : (
        <PrimaryButton
          onClick={() => router.push('/login')}
          size={ThemeSize.Medium}
        >
          Login
        </PrimaryButton>
      )}
    </HeaderWrapper>
  )
}

export default Header
