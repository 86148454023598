import { FC, useEffect, type PropsWithChildren } from 'react'
import { useRouter } from 'next/router'
import { useStytchB2BClient, useStytchMember } from '@stytch/nextjs/b2b'
import { ThemeProvider } from '@emotion/react'

import glisseoTheme from '@lib/theme'

import Footer from '@components/page/footer'
import Header from '@components/page/header'

import { ContentWrapper, PageWrapper } from './style'

interface PageProps extends PropsWithChildren {
  noAuth?: boolean
  noFooter?: boolean
  noHeader?: boolean
}

const Page: FC<PageProps> = ({
  children,
  noAuth = false,
  noFooter = false,
  noHeader = false,
}) => {
  const router = useRouter()
  const { isInitialized, member } = useStytchMember()

  useEffect(() => {
    if (
      typeof noAuth !== 'undefined' &&
      typeof isInitialized !== 'undefined' &&
      typeof router !== 'undefined'
    ) {
      if (!noAuth && isInitialized && router && !member) {
        router.push('/login')
      } else if (
        (!noAuth ||
          router.pathname.startsWith('/login') ||
          !router.pathname.startsWith('/dashboard')) &&
        isInitialized &&
        router &&
        member
      ) {
        router.push('/dashboard')
      }
    }
  }, [isInitialized, noAuth, member, router])

  return (
    <ThemeProvider theme={glisseoTheme}>
      <PageWrapper>
        {!noHeader && <Header />}

        <ContentWrapper>{children}</ContentWrapper>

        {!noFooter && <Footer />}
      </PageWrapper>
    </ThemeProvider>
  )
}

export default Page
